import { default as accept_45invitemJTiBLtiZ3Meta } from "/vercel/path0/pages/accept-invite.vue?macro=true";
import { default as confirm2uAIxw9fKZMeta } from "/vercel/path0/pages/confirm.vue?macro=true";
import { default as create_45profilezKv3oDT3JWMeta } from "/vercel/path0/pages/create-profile.vue?macro=true";
import { default as indexOemEP4Xc50Meta } from "/vercel/path0/pages/dashboard/admin/[schoolId]/index.vue?macro=true";
import { default as insightsllLM4aUdMmMeta } from "/vercel/path0/pages/dashboard/admin/[schoolId]/insights.vue?macro=true";
import { default as inviteJ8d0gqB5wsMeta } from "/vercel/path0/pages/dashboard/admin/[schoolId]/invite.vue?macro=true";
import { default as indexm2SCsZVz29Meta } from "/vercel/path0/pages/dashboard/admin/[schoolId]/students/index.vue?macro=true";
import { default as inviteeExYKZBGMUMeta } from "/vercel/path0/pages/dashboard/admin/[schoolId]/students/invite.vue?macro=true";
import { default as indexvj2HnwwXvyMeta } from "/vercel/path0/pages/dashboard/admin/[schoolId]/teachers/index.vue?macro=true";
import { default as invitetw6sdOMtcgMeta } from "/vercel/path0/pages/dashboard/admin/[schoolId]/teachers/invite.vue?macro=true";
import { default as indexRIIpWdUktDMeta } from "/vercel/path0/pages/dashboard/index.vue?macro=true";
import { default as indexGFGcqTy8pVMeta } from "/vercel/path0/pages/dashboard/student/[schoolId]/index.vue?macro=true";
import { default as insightsmCUTiDrzqLMeta } from "/vercel/path0/pages/dashboard/student/[schoolId]/insights.vue?macro=true";
import { default as system_45adminmUiwPL85cWMeta } from "/vercel/path0/pages/dashboard/system-admin.vue?macro=true";
import { default as indexDr7T5L4Er9Meta } from "/vercel/path0/pages/dashboard/teacher/[schoolId]/index.vue?macro=true";
import { default as insightsc4cRhKDQEgMeta } from "/vercel/path0/pages/dashboard/teacher/[schoolId]/insights.vue?macro=true";
import { default as indexvLMR1mc4VeMeta } from "/vercel/path0/pages/dashboard/teacher/[schoolId]/students/index.vue?macro=true";
import { default as invitecwti8bX90GMeta } from "/vercel/path0/pages/dashboard/teacher/[schoolId]/students/invite.vue?macro=true";
import { default as index5VJlG6PCQRMeta } from "/vercel/path0/pages/games/index.vue?macro=true";
import { default as _91id_93EZj0s0GdVJMeta } from "/vercel/path0/pages/games/math/[id].vue?macro=true";
import { default as indexTDtVXauaA7Meta } from "/vercel/path0/pages/games/math/index.vue?macro=true";
import { default as _91id_93sHplfR0sbnMeta } from "/vercel/path0/pages/games/religion/[id].vue?macro=true";
import { default as index2fFduCpT1yMeta } from "/vercel/path0/pages/games/religion/index.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as logint0AWlhQgM0Meta } from "/vercel/path0/pages/login.vue?macro=true";
import { default as loginOTPgtz5slhqAZMeta } from "/vercel/path0/pages/loginOTP.vue?macro=true";
import { default as registerIikvtlfpbHMeta } from "/vercel/path0/pages/register.vue?macro=true";
export default [
  {
    name: accept_45invitemJTiBLtiZ3Meta?.name ?? "accept-invite",
    path: accept_45invitemJTiBLtiZ3Meta?.path ?? "/accept-invite",
    meta: accept_45invitemJTiBLtiZ3Meta || {},
    alias: accept_45invitemJTiBLtiZ3Meta?.alias || [],
    redirect: accept_45invitemJTiBLtiZ3Meta?.redirect,
    component: () => import("/vercel/path0/pages/accept-invite.vue").then(m => m.default || m)
  },
  {
    name: confirm2uAIxw9fKZMeta?.name ?? "confirm",
    path: confirm2uAIxw9fKZMeta?.path ?? "/confirm",
    meta: confirm2uAIxw9fKZMeta || {},
    alias: confirm2uAIxw9fKZMeta?.alias || [],
    redirect: confirm2uAIxw9fKZMeta?.redirect,
    component: () => import("/vercel/path0/pages/confirm.vue").then(m => m.default || m)
  },
  {
    name: create_45profilezKv3oDT3JWMeta?.name ?? "create-profile",
    path: create_45profilezKv3oDT3JWMeta?.path ?? "/create-profile",
    meta: create_45profilezKv3oDT3JWMeta || {},
    alias: create_45profilezKv3oDT3JWMeta?.alias || [],
    redirect: create_45profilezKv3oDT3JWMeta?.redirect,
    component: () => import("/vercel/path0/pages/create-profile.vue").then(m => m.default || m)
  },
  {
    name: indexOemEP4Xc50Meta?.name ?? "dashboard-admin-schoolId",
    path: indexOemEP4Xc50Meta?.path ?? "/dashboard/admin/:schoolId()",
    meta: indexOemEP4Xc50Meta || {},
    alias: indexOemEP4Xc50Meta?.alias || [],
    redirect: indexOemEP4Xc50Meta?.redirect,
    component: () => import("/vercel/path0/pages/dashboard/admin/[schoolId]/index.vue").then(m => m.default || m)
  },
  {
    name: insightsllLM4aUdMmMeta?.name ?? "dashboard-admin-schoolId-insights",
    path: insightsllLM4aUdMmMeta?.path ?? "/dashboard/admin/:schoolId()/insights",
    meta: insightsllLM4aUdMmMeta || {},
    alias: insightsllLM4aUdMmMeta?.alias || [],
    redirect: insightsllLM4aUdMmMeta?.redirect,
    component: () => import("/vercel/path0/pages/dashboard/admin/[schoolId]/insights.vue").then(m => m.default || m)
  },
  {
    name: inviteJ8d0gqB5wsMeta?.name ?? "dashboard-admin-schoolId-invite",
    path: inviteJ8d0gqB5wsMeta?.path ?? "/dashboard/admin/:schoolId()/invite",
    meta: inviteJ8d0gqB5wsMeta || {},
    alias: inviteJ8d0gqB5wsMeta?.alias || [],
    redirect: inviteJ8d0gqB5wsMeta?.redirect,
    component: () => import("/vercel/path0/pages/dashboard/admin/[schoolId]/invite.vue").then(m => m.default || m)
  },
  {
    name: indexm2SCsZVz29Meta?.name ?? "dashboard-admin-schoolId-students",
    path: indexm2SCsZVz29Meta?.path ?? "/dashboard/admin/:schoolId()/students",
    meta: indexm2SCsZVz29Meta || {},
    alias: indexm2SCsZVz29Meta?.alias || [],
    redirect: indexm2SCsZVz29Meta?.redirect,
    component: () => import("/vercel/path0/pages/dashboard/admin/[schoolId]/students/index.vue").then(m => m.default || m)
  },
  {
    name: inviteeExYKZBGMUMeta?.name ?? "dashboard-admin-schoolId-students-invite",
    path: inviteeExYKZBGMUMeta?.path ?? "/dashboard/admin/:schoolId()/students/invite",
    meta: inviteeExYKZBGMUMeta || {},
    alias: inviteeExYKZBGMUMeta?.alias || [],
    redirect: inviteeExYKZBGMUMeta?.redirect,
    component: () => import("/vercel/path0/pages/dashboard/admin/[schoolId]/students/invite.vue").then(m => m.default || m)
  },
  {
    name: indexvj2HnwwXvyMeta?.name ?? "dashboard-admin-schoolId-teachers",
    path: indexvj2HnwwXvyMeta?.path ?? "/dashboard/admin/:schoolId()/teachers",
    meta: indexvj2HnwwXvyMeta || {},
    alias: indexvj2HnwwXvyMeta?.alias || [],
    redirect: indexvj2HnwwXvyMeta?.redirect,
    component: () => import("/vercel/path0/pages/dashboard/admin/[schoolId]/teachers/index.vue").then(m => m.default || m)
  },
  {
    name: invitetw6sdOMtcgMeta?.name ?? "dashboard-admin-schoolId-teachers-invite",
    path: invitetw6sdOMtcgMeta?.path ?? "/dashboard/admin/:schoolId()/teachers/invite",
    meta: invitetw6sdOMtcgMeta || {},
    alias: invitetw6sdOMtcgMeta?.alias || [],
    redirect: invitetw6sdOMtcgMeta?.redirect,
    component: () => import("/vercel/path0/pages/dashboard/admin/[schoolId]/teachers/invite.vue").then(m => m.default || m)
  },
  {
    name: indexRIIpWdUktDMeta?.name ?? "dashboard",
    path: indexRIIpWdUktDMeta?.path ?? "/dashboard",
    meta: indexRIIpWdUktDMeta || {},
    alias: indexRIIpWdUktDMeta?.alias || [],
    redirect: indexRIIpWdUktDMeta?.redirect,
    component: () => import("/vercel/path0/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: indexGFGcqTy8pVMeta?.name ?? "dashboard-student-schoolId",
    path: indexGFGcqTy8pVMeta?.path ?? "/dashboard/student/:schoolId()",
    meta: indexGFGcqTy8pVMeta || {},
    alias: indexGFGcqTy8pVMeta?.alias || [],
    redirect: indexGFGcqTy8pVMeta?.redirect,
    component: () => import("/vercel/path0/pages/dashboard/student/[schoolId]/index.vue").then(m => m.default || m)
  },
  {
    name: insightsmCUTiDrzqLMeta?.name ?? "dashboard-student-schoolId-insights",
    path: insightsmCUTiDrzqLMeta?.path ?? "/dashboard/student/:schoolId()/insights",
    meta: insightsmCUTiDrzqLMeta || {},
    alias: insightsmCUTiDrzqLMeta?.alias || [],
    redirect: insightsmCUTiDrzqLMeta?.redirect,
    component: () => import("/vercel/path0/pages/dashboard/student/[schoolId]/insights.vue").then(m => m.default || m)
  },
  {
    name: system_45adminmUiwPL85cWMeta?.name ?? "dashboard-system-admin",
    path: system_45adminmUiwPL85cWMeta?.path ?? "/dashboard/system-admin",
    meta: system_45adminmUiwPL85cWMeta || {},
    alias: system_45adminmUiwPL85cWMeta?.alias || [],
    redirect: system_45adminmUiwPL85cWMeta?.redirect,
    component: () => import("/vercel/path0/pages/dashboard/system-admin.vue").then(m => m.default || m)
  },
  {
    name: indexDr7T5L4Er9Meta?.name ?? "dashboard-teacher-schoolId",
    path: indexDr7T5L4Er9Meta?.path ?? "/dashboard/teacher/:schoolId()",
    meta: indexDr7T5L4Er9Meta || {},
    alias: indexDr7T5L4Er9Meta?.alias || [],
    redirect: indexDr7T5L4Er9Meta?.redirect,
    component: () => import("/vercel/path0/pages/dashboard/teacher/[schoolId]/index.vue").then(m => m.default || m)
  },
  {
    name: insightsc4cRhKDQEgMeta?.name ?? "dashboard-teacher-schoolId-insights",
    path: insightsc4cRhKDQEgMeta?.path ?? "/dashboard/teacher/:schoolId()/insights",
    meta: insightsc4cRhKDQEgMeta || {},
    alias: insightsc4cRhKDQEgMeta?.alias || [],
    redirect: insightsc4cRhKDQEgMeta?.redirect,
    component: () => import("/vercel/path0/pages/dashboard/teacher/[schoolId]/insights.vue").then(m => m.default || m)
  },
  {
    name: indexvLMR1mc4VeMeta?.name ?? "dashboard-teacher-schoolId-students",
    path: indexvLMR1mc4VeMeta?.path ?? "/dashboard/teacher/:schoolId()/students",
    meta: indexvLMR1mc4VeMeta || {},
    alias: indexvLMR1mc4VeMeta?.alias || [],
    redirect: indexvLMR1mc4VeMeta?.redirect,
    component: () => import("/vercel/path0/pages/dashboard/teacher/[schoolId]/students/index.vue").then(m => m.default || m)
  },
  {
    name: invitecwti8bX90GMeta?.name ?? "dashboard-teacher-schoolId-students-invite",
    path: invitecwti8bX90GMeta?.path ?? "/dashboard/teacher/:schoolId()/students/invite",
    meta: invitecwti8bX90GMeta || {},
    alias: invitecwti8bX90GMeta?.alias || [],
    redirect: invitecwti8bX90GMeta?.redirect,
    component: () => import("/vercel/path0/pages/dashboard/teacher/[schoolId]/students/invite.vue").then(m => m.default || m)
  },
  {
    name: index5VJlG6PCQRMeta?.name ?? "games",
    path: index5VJlG6PCQRMeta?.path ?? "/games",
    meta: index5VJlG6PCQRMeta || {},
    alias: index5VJlG6PCQRMeta?.alias || [],
    redirect: index5VJlG6PCQRMeta?.redirect,
    component: () => import("/vercel/path0/pages/games/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93EZj0s0GdVJMeta?.name ?? "games-math-id",
    path: _91id_93EZj0s0GdVJMeta?.path ?? "/games/math/:id()",
    meta: _91id_93EZj0s0GdVJMeta || {},
    alias: _91id_93EZj0s0GdVJMeta?.alias || [],
    redirect: _91id_93EZj0s0GdVJMeta?.redirect,
    component: () => import("/vercel/path0/pages/games/math/[id].vue").then(m => m.default || m)
  },
  {
    name: indexTDtVXauaA7Meta?.name ?? "games-math",
    path: indexTDtVXauaA7Meta?.path ?? "/games/math",
    meta: indexTDtVXauaA7Meta || {},
    alias: indexTDtVXauaA7Meta?.alias || [],
    redirect: indexTDtVXauaA7Meta?.redirect,
    component: () => import("/vercel/path0/pages/games/math/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93sHplfR0sbnMeta?.name ?? "games-religion-id",
    path: _91id_93sHplfR0sbnMeta?.path ?? "/games/religion/:id()",
    meta: _91id_93sHplfR0sbnMeta || {},
    alias: _91id_93sHplfR0sbnMeta?.alias || [],
    redirect: _91id_93sHplfR0sbnMeta?.redirect,
    component: () => import("/vercel/path0/pages/games/religion/[id].vue").then(m => m.default || m)
  },
  {
    name: index2fFduCpT1yMeta?.name ?? "games-religion",
    path: index2fFduCpT1yMeta?.path ?? "/games/religion",
    meta: index2fFduCpT1yMeta || {},
    alias: index2fFduCpT1yMeta?.alias || [],
    redirect: index2fFduCpT1yMeta?.redirect,
    component: () => import("/vercel/path0/pages/games/religion/index.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index",
    path: indexe9Brt5DfdhMeta?.path ?? "/",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: logint0AWlhQgM0Meta?.name ?? "login",
    path: logint0AWlhQgM0Meta?.path ?? "/login",
    meta: logint0AWlhQgM0Meta || {},
    alias: logint0AWlhQgM0Meta?.alias || [],
    redirect: logint0AWlhQgM0Meta?.redirect,
    component: () => import("/vercel/path0/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginOTPgtz5slhqAZMeta?.name ?? "loginOTP",
    path: loginOTPgtz5slhqAZMeta?.path ?? "/loginOTP",
    meta: loginOTPgtz5slhqAZMeta || {},
    alias: loginOTPgtz5slhqAZMeta?.alias || [],
    redirect: loginOTPgtz5slhqAZMeta?.redirect,
    component: () => import("/vercel/path0/pages/loginOTP.vue").then(m => m.default || m)
  },
  {
    name: registerIikvtlfpbHMeta?.name ?? "register",
    path: registerIikvtlfpbHMeta?.path ?? "/register",
    meta: registerIikvtlfpbHMeta || {},
    alias: registerIikvtlfpbHMeta?.alias || [],
    redirect: registerIikvtlfpbHMeta?.redirect,
    component: () => import("/vercel/path0/pages/register.vue").then(m => m.default || m)
  }
]