export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.config.errorHandler = (error, instance, info) => {
    useBugsnag().notify(error, {
      severity: 'error',
      context: 'error uncaught',
      metaData: {
        info,
      },
    })
  }
})
