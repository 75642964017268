import VueHotjar from 'vue-hotjar-next'
export default defineNuxtPlugin((nuxtApp) => {
  // const config = useRuntimeConfig()

  nuxtApp.vueApp.use(VueHotjar, {
    id: 3690204, // the app id given from Hotjar.
    isProduction: true, // config.environment === 'prod',
    snippetVersion: 6,
  })
})
